<script>
import {mapGetters} from "vuex";
import {debounce} from "@/utils/debounce";
import {poker_change_user_state} from "@/gameSock";

export default {
  name: "LeaveRoomReservation",
  data() {
    return {
      isRequesting: false,
      isHovering: false,
      isMenuOpen: false,
    }
  },
  computed: {
    ...mapGetters('game', ['roomInfo', 'myInfo', 'isPlaying', 'isRelayGame']),
    ...mapGetters('user', ['isGameExitReserved']),
    isAbleReserveLeave(){
      return this.isRelayGame && this.isPlayerPlayingGame;
    },
    isPlayerPlayingGame() {
      if (!this.isPlaying) {
        return false;
      }

      try {
        const {idx, playState, reserveState} = this.myInfo;

        if (reserveState === this.playState.WAIT_FROM_ENTER) {
          return false;
        }

        return idx !== -1 && [this.playState.PLAYING, this.playState.FOLD].some(state => state === playState);
      } catch (e) {
        return false;
      }
    },
    isLeaveRoomReserved: {
      get() {

        //시간초과로 자리부움 되었을때 나가기예약버튼 초기화
        const {reserveState} = this.myInfo;
        if( reserveState === this.playState.SITOUT ){
          this.$store.commit('user/SET_IS_GAME_EXIT_RESERVED', false);
        }

        return this.isGameExitReserved;
      },
      set(state) {
        this.$store.commit('user/SET_IS_GAME_EXIT_RESERVED', state);
      }
    },
  },
  methods: {
    closeDropdown() {
      this.isMenuOpen = false;
    },
    reserve: debounce(async function () {
      this.closeDropdown();

      if (!this.isAbleReserveLeave) {
        this.$emit('leave');
        return;
      }

      if (this.isRequesting) {
        alert('요청을 처리 중입니다. 잠시만 기다려 주세요.');
        return;
      }

      this.isRequesting = true;

      const action = this.isLeaveRoomReserved ? `cancel-leave-room-reservation` : `leave-room-reservation`;

      this.isLeaveRoomReserved = !this.isLeaveRoomReserved;

      try {
        await poker_change_user_state(
          this.$socket,
          this.roomInfo.sn,
          action
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.isRequesting = false;
      }
    }, 500, {leading: true}),
    handleMouseEnter() {
      this.handleHover(true);
    },
    handleMouseLeave() {
      this.handleHover(false);
    },
    handleTouchStart() {
      this.handleHover(true);
    },
    handleTouchEnd() {
      this.handleHover(false);
    },
    handleHover(isHovering) {
      this.isHovering = isHovering;
    },
    onLeaveAction() {
      if (!this.isAbleReserveLeave) {
        this.$emit('leave');
        return;
      }

      this.isMenuOpen = !this.isMenuOpen;
    },
    onMenuBlur({relatedTarget}) {
      const action = relatedTarget && relatedTarget.dataset && relatedTarget.dataset.action;

      switch (action) {
        case 'leave':
          this.reserve();
          break;
        default:
          break;
      }

      this.isMenuOpen = false;
    }
  },
  created() {
    this.isLeaveRoomReserved = false;
  }
}
</script>

<template>
  <div
    tabindex="-1"
    class="out_menu"
    :class="{open: isMenuOpen}"
    @focusout="onMenuBlur"
  >
    <a
      type="button"
      class="btn_d btn_out"
      :class="{ active: isLeaveRoomReserved }"
      @click.prevent.stop="onLeaveAction"
    >
    </a>
    <ul v-if="isAbleReserveLeave" class="out_menu_list" :class="{'d-none': !isMenuOpen}">
      <li @click.prevent.stop="reserve">
        <a class="dropdown-item"
           data-action="leave"
           href="javascript:void(0);"
        >
        <span class="align-middle me-3">
          <img v-show="!isLeaveRoomReserved" src="@/assets/v2/svg/ico_logout.svg" alt="" width="25">
          <img v-show="isLeaveRoomReserved" src="@/assets/v2/svg/ico_logout_c.svg" alt="" width="25">
        </span>
        <span class="align-middle">
          나가기 {{ isLeaveRoomReserved ? '취소' : '예약' }}
        </span>
        </a>
      </li>
    </ul>
  </div>

  <!--
  <div>
    <button class="btn_d btn_out leave"
            @click.prevent.stop="reserve"
    >
      <img src="@/assets/v2/svg/logout.svg" alt="나가기 예약">

      <transition name="slide-fade" mode="out-in">
        <div v-if="isPlayerPlayingGame" class="txt">나가기 {{ isLeaveRoomReserved ? '취소' : '예약' }}</div>
      </transition>
    </button>
  </div>
  -->
</template>

<style scoped lang="scss">
.dropdown.member_menu.open .dropdown-menu {
  display: block;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: .5s;
}

.slide-fade-enter, .slide-fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  transform: translateY(1rem);
  transform-origin: center center;
  opacity: 0;
}

/*
div.leave-room-reservation {
  cursor: pointer;
  transition: .2s ease-in-out;
}

.leave-room-reservation__bg {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.1;
  color: #fff;
  border-radius: .5rem;
  z-index: 1;
}

button.leave-room-reservation {
  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.25rem;
  line-height: 2.5rem;
  color: #ccc;
  vertical-align: middle;
  font-weight: bold;
}
*/

button.leave {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
</style>